export const leadingIndustriesSliderConfig = {
  responsive: [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 970,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const sampleTendorsSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const lastWinnersSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const testinomialSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const termsOfUseLists = [
  {
    title: "כללי",
    list: [
      'הוראות התקנון שלהלן מגדירות את זכויות וחובות המשתמש בקשר עם השימוש באתר האינטרנט (להלן: "התקנון"). ',
      'התקנון מהווה חוזה התקשרות מחייב בין המשתמשת לבין חברת יפעת (להלן: "החברה ו/או מנהלי האתר"), לכל דבר ועניין. ',
      "החברה רשאית לבצע שינויים ו/או עדכונים בתנאי שימוש אלה בכל זמן או עת, ללא הודעה מוקדמת ו/או עדכון.  ",
      "המשתמש מתחייבת לחזור ולעיין בתנאי השימוש מידי פעם על מנת להתרענן ולהתעדכן בתנאים ובשינויים אם היו כאילו. השימוש באתר מהווה הסכמה להיות כפופים לאמור בתנאי השימוש בכל עת ולפעול באתר בהתאם. ",
      "אין לעשות באתר או באמצעותו כל שימוש למטרות בלתי חוקיות.",
      'בכל מקרה של סתירה בין הוראות תקנון זה להוראות כל מסמך אחר, תגברנה הוראות תקנון זה. הרישום במחשבי החברה יהווה ראיה מכרעת לנכונות רישומים אשר תנהל החברה ע"פ תקנון זה. ',
      "התקנון מנוסח בלשון זכר אך מתייחס לשני המינים. ",
    ],
  },
  {
    title: "התכנים והמידע המוצגים באתר",
    list: [
      "יתכן ותכנים מסוימים חסרים ו/או נפלו טעויות כאלה ואחרות בתוכן האתר, לרבות ברכיבי תוכנה. יפעת לא תישא בכל אחריות לכל נזק, הוצאה ו/או אובדן שיגרמו למשתמשים עקב טעויות ו/או אי דיוקים במידע כאמור.",
      "ידוע ומוסכם על המשתמש שהגישה לחלקים מן האתר עשויה להיות מותנה בהתקנת רכיבי חומרה שונים במחשבו ובכלל זאת: דפדפנים ותוספים לאותם הדפדפנים. על המשתמש חלה האחריות לברר ולהתקין כל רכיב נדרש לצורך השימוש באתר, על חשבונו ואחריותו.",
      "החברה רשאית להגביל את הגישה לאתר או לחלקים ממנו לסוגי משתמשים שונים ו/או ללקוחותיה ו/או לחלקם ו/או להתנות את הגישה אליהם למשתמשים שירשמו באתר או שיאשרו את הסכמתם לתנאים מיוחדים מסוימים.אין בפרסומות לאתר או ממנו כדי ליצור בין המשתמש לבין יפעת יחסים משפטיים כלשהם, למעט חובותיו המשפטיות של המשתמש כמפורט בתקנון זה. זכויות הצדדים הן אלו המפורטות בתנאי שימוש אלו, ואין בשימוש באתר או בפרסומות כאמור כדי להקנות כל זכויות נוספות.",
      "במידה ותתגלה אי התאמה ו/או סתירה בין התוכן באתר לבין מידע הקיים במסמכיה הרשמיים של החברה - יחשב כנכון המידע הנמצא במסמכים הרשמיים של החברה.",
    ],
  },
  {
    title: "שמירה על פרטיות ",
    list: [
      "לחברה חשובה פרטיותך והיא מחויבת לשמור על כללי הגנת הפרטיות החלים בישראל, שימוש במידע אישי אשר יימסר על-ידך או ייאסף אודותייך ייעשה אך ורק בהתאם למפורט במדיניות פרטיות זו או על-פי חובות החלות כלפי החברה לפי כל דין .",
      'המשתמש מאשר בזאת ומסכים, כי ככל ומולאו על ידיו פרטים אישיים לצורך שימוש באתר האינטרנט, הם נמסרו על ידו מתוך הסכמה, בחירה ורצון חופשי וכן לא חלה עליו חובה חוקית למסור את הנתונים הנ"ל.',
      "כאמור, חברת דפני עושה כל שביכולתה, וכנדרש בחוק, להגן על פרטי המשתמשים השמורים במאגריה, כמו כן במקרה בו יעלה בידי צד שלישי לשבש ו/או להפריע ו/או לחדור ו/או לעשות שימוש לרעה ו/או לעשות כל פעולה אחרת שלא כדין בפרטי המשתמשת, לא תהיינה למשתמשת ו/או מי מטעמו כל טענה ו/או דרישה לחברה.",
      "החברה רשאית לבצע שימוש בפרטיו האישיים של המשתמש, ללא זיהוי ספציפי, לצורך ניתוח מידע סטטיסטי והצגתו ו/או מסירתו לגורמים שונים וצדדים שלישיים.",
      "יובהר כי מאחר והפעולות מתבצעות בסביבה מקוונת אין החברה יכולה להבטיח חסינות מוחלטת מפני חדירות למחשביה, או חשיפת המידע האמור בידי מבצעי פעולות בלתי חוקיות. אם יעלה בידי צד שלישי לחדור למידע אשר שמור בידי החברה ו/או להשתמש בו לרעה, לא תהיה למשתמש כל טענה, תביעה או דרישה כלפי החברה.",
      "החברה תהא רשאית לעשות שימוש ב – “עוגיות” (“cookies”) על מנת לספק למשתמש שירות מהיר.",
      'החברה וספקים שונים של צדדים שלישיים משתמשת ב"עוגיות" ("cookies") על מנת להציג מודעות עבורך בהתאמה אישית, על פי ביקוריך הקודמים באתרים שונים. ',
      "המשתמש רשאי לבטל הסכמתו לפרסום מותאם אישית האמור באמצעות הגדרות המודעות שבדפדפו האינטרנט שבו הנך עושה שימוש, או לחלופין באמצעות אתר  www.aboutads.info.",
    ],
  },
  {
    title: "פרסומות של צדדים שלישיים",
    list: [
      "החברה משתמשת בצדדים שלישיים לניהול הצגת הפרסומות באתר וכן משתמשת במערכות מטעם חברות אחרות כדי לנהל את מערך הצגת הפרסומות באתר.",
      'לצורך ניהול הפרסומות, יכול והצדדים השלישיים ישתמשו  במחשבך באובייקטים שונים כגון Cookies ומשבצות "משואות רשת" (web beacons) במודעות הפרסומות או בדפי האתר, כאמור לעיל.  המידע הנאסף אינו מזהה אותך אישית אלא רק מתאים את הפרסומות שיוצגו בפניך כמפורט לעיל. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן בלבד והנך מוזמן לבדוק את מדיניות הפרטיות של אותן חברות באמצעות אתרי האינטרנט שלהן.',
      "כמו כן באפשרותך לבטל הסכמתך להצגת מודעות אלו באופן המתואר לעיל.",
    ],
  },
  {
    title: "קניין רוחנ",
    list: [
      "עיצוב האתר, המידע המופיע בו, התוכנה, סימני המסחר, יישום, טקסט, קובץ גרפי, תוכן וכל חומר אחר, הינם קניינה הבלעדי של אזורים או של צדדים שלישיים שהתירו לאזורים להשתמש בהם והם מוגנים על ידי הדין, לרבות חוקי מדינת ישראל, אמנות בינלאומיות ודיני מדינות זרות. שימוש בקניין הרוחני באתר מחייב הסכמה בכתב ומראש של החברה וככל שלא נתקבלה ההסכמה האמורה נאסר עליו לגרום לכל שינוי או סילוף במידע, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות או למכור או להשכיר כל חלק מן המידע הכלול באתר ו/או לעשות שימוש בשמות ובסימנים המסחריים המוצגים באתר.",
    ],
  },
  {
    title: "אחריות",
    list: [
      "המשתמש מצהיר ומתחייב לשאת ו/או לשפות ו/או לפצות את החברה בגין כל נזק שיגרם לה ו/או שותפיה ו/או סוכניה ו/או עובדיה ו/או נושאי המשרה ו/או המנהלים ו/או הדירקטורים ו/או בעלי המניות ו/או משתמשים אחרים וכו', כולל, אך לא רק, כל אחריות שהיא, תביעה, נזק ו/או הפסד (כולל הוצאות משפטיות), שינבעו כתוצאה משימוש, שימוש בלתי ראוי ו/או מוטעה, הפרעה ו/או כשלון ו/או כל פעולה אסורה על פי חוק המחשבים, התשנ'ה-1995, חוק הגנת הפרטיות, התשמ'א -1981, חוק איסור לשון הרע, התשכ'ה - 1965, חוק עוולות מסחריות, התשנ'ט - 1999, פקודת הנזיקין, התשכ'ח - 1968 ו/או על פי כל דין, שיעשה באתר ו/או באמצעותו, במידע, תכניו ו/או בשירותים בו.",
    ],
  },
  {
    title: "הדין החל וסמכות שיפוט",
    list: [
      "תנאי שימוש אלה כפופים אך ורק לחוקי מדינת ישראל. כל טענה ו/או דרישה ו/או תביעה הנוגעת לתנאי שימוש אלה ו/או לשימוש באתר יהיו בסמכות שיפוט בלעדית של בית המשפט המוסמך בתל-אביב, ישראל.",
    ],
  },
];

export const qanda = [
  {
    title: "באיזה אופן מקבלים את המכרזים?",
    answer:
      "מכרזים מגיע בדחיפה באמצעות סוכן חכם למייל על פי הגדרת התחום ותתי ענפים.",
  },
  {
    title: "האם המכרזים במגוון נושאים?",
    answer: "מכרזים מתפרסמים בכל ענפי המשק, מקוטלגים אצלנו על פי 70 ענפים.",
  },
  {
    title: "האם יש מספר מסלולים למנויים?",
    answer: "יש מסלולו שנתי ויש מסלול חודשי.",
  },
  {
    title: "האם השרות מתאים לכל בית עסק?",
    answer: "השרות מתאים לכל עסק שמחפש להגדיל את מקורות העבודה שלו.",
  },
  {
    title: "האם יש עזרה בהגשת מכרזים?",
    answer: "אנו מסייעים בהתאמת המכרז ואף נותנים ליווי מלא ללקוח במידת הצורך.",
  },
  {
    title: "אילו שירותים נוספים ניתן לקבל מיפעת מכרזים?",
    answer:
      " יש לנו מסלול של מידע מגוון בעיקר בענף הבנייה והשירותים הנילווים לענף: מאגר פרויקטים,  אנשים שבונים בתים פרטיים.מכרזי בנייה עם תמצית כתבי כמויות.",
  },
];

export const newTenders = [
  { title: "localAuthority", link: "/Category/101/localAuthority/static" },
  { title: "beerSheva", link: "/Category/102/beerSheva/static" },
  { title: "hodHasharon", link: "/Category/103/hodHasharon/static" },
  { title: "nativot", link: "/Category/104/nativot/static" },
  { title: "negevFields", link: "/Category/105/negevFields/static" },
  { title: "ramatHasharon", link: "/Category/106/ramatHasharon/static" },
  { title: "sakhnin", link: "/Category/107/sakhnin/static" },
  { title: "freight", link: "/Category/108/freight/static" },
  { title: "lottery", link: "/Category/109/lottery/static" },
  { title: "earthworks", link: "/Category/110/earthworks/static" },
  { title: "jointsIsrael", link: "/Category/111/jointsIsrael/static" },
  { title: "graphicDesign", link: "/Category/112/graphicDesign/static" },
  {
    title: "unitedHealthInsurence",
    link: "/Category/113/unitedHealthInsurence/static",
  },
  {
    title: "nationalHealthInsurence",
    link: "/Category/114/nationalHealthInsurence/static",
  },
];

export const staticTenders = [
  {
    shortName: "localAuthority",
    name: "מכרזי הרשויות המקומיות",
    title: "מכרזי הרשויות המקומיות: פרסום המידע העדכני ביותר | יפעת מכרזים",
    description:
      "מחפשים את הפרסום העדכני ביותר למכרזי הרשויות המקומיות? יפעת מכרזים בעלי ניסיון של יותר מ-40 שנה באיתור ופרסום של מכרזי הרשויות המקומיות להגשת מועמדות לחצו כאן",
    text: `<b>התפתחות עסקית בשוק התחרותי של זמננו מחייבת איתור מתמיד של הזדמנויות עסקיות, שיכולות להגיע בדמות מכרזים פומביים או בלעדיים, התקשרויות הפטורות ממכרז ועוד. על מנת לא להחמיץ את כל אלו, מומלץ לפנות לגורם מקצועי שזהו תחום התמחותו ושניחן בניסיון רב שנים וברקורד של הצלחה מוכחת בתחום.</b><br/><br/>
            <b>מכרזי הרשויות המקומיות – איך להישאר מעודכנים?</b><br/><br/>
            יפעת מכרזים הוקמה לפני למעלה מארבעה  עשורים ומאז מובילה את שוק המכרזים וההזדמנויות העסקיות בישראל. עם עשרות מידענים ומנהלי תיקי לקוחות, אנו מספקים לכל לקוח שירות אישי המותאם במדויק לצרכיו, ודואגים להנגיש עבורו הזדמנויות עסקיות רלוונטיות במגוון רחב של קטגוריות ותחומי פעילות כאלו ואחרים במשק.<br/><br/>
            <b>למה כדאי להיעזר בנו לאיתור מכרזי הרשויות המקומיות?</b><br/><br/>
            ישנם מספר יתרונות בולטים לעבודה עם גורם מקצועי <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">המתמחה באיתור ובקיטלוג אלפי מכרזים</a> והזדמנויות עסקיות:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מקצועיות –</b> יפעת מכרזים מקפידה להעסיק אך ורק צוות המורכב ממיטב אנשי המקצוע בתחום, על מנת לוודא שכל אחד מלקוחותינו יקבל שירות ותוצרים ברמה הגבוהה ביותר.</li>
            <li><b>ניסיון –</b> עם רקורד של למעלה מארבעים  שנה בתחום, יש לנו את הידע והמיומנות שיאפשרו לעסק שלך להגדיל את מחזור המכירות שלו ובעתיד, את רווחיו, באופן משמעותי.</li>
            <li><b>הצלחה  –</b> ההצלחה שלנו מדברת בעד עצמה. כיום אנו החברה המובילה בישראל בתחומנו, עם אלפי לקוחות מרוצים שיכולים להעיד על הצמיחה העסקית שהשירות שלנו איפשר להם להגיע אליה. </li>
            <li><b>שירות  –</b> אנשי המקצוע שלנו ניחנו בתודעת שירות גבוהה ומחויבים להעניק יחס אישי אדיב, מסור ואכפתי לכל לקוח.</li>
            </ul><br/>
            <b>היכן תמצאו מידע על מכרזי הרשויות המקומיות?</b><br/><br/>
            אחד מתחומי ההתמחות שלנו הוא הנגשת מכרזי עיריות ללקוחותינו. בין אם אתם בעלי עסקים, מומחי נדל"ן, מנהלי רכש ולוגיסטיקה או מנהלי פיתוח עסקי ומכירות, היכולת לדעת בזמן אמת על ההזדמנויות העסקיות הרלוונטיות ביותר עבורכם היא נכס חשוב שאין לו תחליף.<br/></br/>
            אצלנו תוכלו לקבל מידע מלא על מכרזים והתקשרויות המחולקים לפי הרשויות המקומיות השונות, ולדעת מה מציעה כל אחת מהן. השירות שאנו מציעים כולל מידע נגיש על מכרזים עיריות ורשויות מקומיות שונות באופן שיאפשר לכם לעקוב בזמן אמת אחר האפשרויות שיסייעו לכם לצמוח מבחינה עסקית.<br/><br>
            <b>מהו הפוטנציאל העסקי של מכרזי הרשויות המקומיות?</b><br/><br/>
            ישנו פוטנציאל עסקי לא מבוטל בתחום הרחב של מכרזי הרשויות המקומיות הנובע במישרין מהחשיבות של מכרזים פומביים או בלעדיים ושל התקשרויות פטורות ממכרז להרחבת פעילות העסק שלכם, מחזור המכירות וההכנסות העתידיות שלכם.<br/><br/>
            יפעת מכרזים מיועדת לאפשר לכם להשיג מידע בלעדי, זמין ועדכני בכל רגע נתון על מנת לוודא שלא תחמיצו את ההזדמנויות שיאפשרו לעסק שלכם לצמוח באופן הטוב ביותר ולקדם את פעילותו לשלב הבא.<br/><br/>
            בין אם מדובר על מכרזים רשויות מקומיות או עיריות, מידע נגיש הוא תנאי הכרחי ליכולת שלכם להתקדם ולהביא את הפעילות העסקית שלכם לרמה גבוהה יותר של רווחיות, הצלחה ואפקטיביות.</br><br/>
            <b>מכרזי הרשויות המקומיות – המפתח להצלחה</b><br/><br/>
            יפעת מכרזים תאתר עבורכם מכרזים פומביים,  , חיצוניים ועוד, ביותר מ-70 ענפים במקצועות שונים והממוינים בהתאם לסעיפי המכרז.<br/><br/>
            אם ברצונכם לחפש מכרזים ברשות מקומית מסוימת, תוכלו כאמור לפלח את התוצאות בהתאם לרשויות – ולגשת למכרז אם העסק שלכם הוא עסק קטן, בינוני או גדול העומד בתנאים המפורטים באותו מכרז.<br/><br/>
            המומחים שלנו ידאגו שתקבלו את המידע העדכני, הרלוונטי והזמין ביותר בזמן אמת, על מנת לוודא שלא תחמיצו הזדמנויות עסקיות שיוכלו לקדם את העסק שלכם ולהעניק לכם את האפשרות להגדיל את פעילותו ואת רווחיו באופן משמעותי.<br/><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "beerSheva",
    name: "מכרזים עיריית באר שבע",
    title: "מכרזים עיריית באר שבע: חשיפה למאות הזדמנויות עסקיות | יפעת מכרזים",
    description:
      "מחפשים את המכרזים העדכניים ביותר של עיריית באר שבע? יפעת מכרזים מספקים עבורכם מאגר מידע למכרזי עיריית באר שבע המתעדכן באופן שוטף, להגשת מועמדות לחצו כאן",
    text: `<b>בין אם אתם בעלי עסקים המעוניינים להגדיל באופן משמעותי את רווחיכם, ובין אם מנהלי רכש ולוגיסטיקה, מומחי נדל"ן או מנהלי מכירות ופיתוח עסקי – ידע מקיף ועדכני בזמן אמת על מכרזים והתקשרויות עסקיות הרלוונטיות מבחינתכם הוא תנאי הכרחי לצמיחה עסקית.</b><br/><br/>
            <b>היכן תמצאו מידע על מכרזים עיריית באר שבע?</b><br/><br/>
            הדבר נכון על אחת כמה וכמה אם אתם זקוקים למידע לגבי מכרזים פומביים או בלעדיים או התקשרויות פטורות ממכרז של עירייה או רשות מקומית ספציפית. רק גוף מקצועי המסוגל לפלח את התוצאות עבורכם ולהציגן באופן הממוין לפי תנאי המכרז והרשויות השונות, ובמגוון רחב של ענפים ותחומים, יוכל להבטיח שלא תחמיצו את ההזדמנות שחיכיתם לה.<br/><br/>
            יפעת מכרזים הוקמה לפני למעלה מארבעה עשורים במטרה לספק את השירות הטוב ביותר עבור כל לקוח המעוניין להגדיל את פעילותו העסקית באמצעות מכרזים והתקשרויות. עם עשרות מידענים ומנהלי תיקי לקוחות המספקים שירות אישי המותאם לצרכיו הייחודיים של כל לקוח, אנו כיום הגורם המקצועי המוביל בישראל באיתור ובקיטלוג מכרזים.<br/><br/>
            <b>למה כדאי להיעזר בנו לחיפוש מכרזים עיריית באר שבע?</b><br/><br/>
            אחד היתרונות הטמונים בעבודה עם גורם מקצועי שזהו תחום התמחותו, הוא האפשרות לנהל את החיפוש שלכם באופן המותאם במדויק לצרכיכם העסקיים – ולקבל תוצאות עדכניות, מלאות ורלוונטיות בהתאם.<br/><br/>
            האפשרות, למשל, לנהל חיפוש באמצעות מילות מפתח כגון מכרזים עיריית באר שבע ועוד, פותחת בפניכם פתח לידע עשיר על מכרזים פומביים,   או חיצוניים הקשורים לעירייה זו, שתוכלו להתמודד במסגרתם, אם העסק שבבעלותכם הוא עסק קטן, בינוני או גדול העומד בתנאי המכרז המסוים שאתם מעוניינים לגשת אליו.<br/><br/>
            <b>מה היתרונות הבולטים של מכרזים עיריית באר שבע?</b><br/><br/>
            ישנם מספר יתרונות מובהקים בהגשת מועמדות למכרזי עיריות ורשויות מקומיות: <br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>שקיפות –</b> אחד היתרונות הבולטים שבהגשת מועמדות למכרז כזה או אחר של גורם ציבורי, כגון עיריית באר שבע, הוא העובדה שתנאי המכרז גלויים בפניכם ושביכולתכם לדעת בדיוק האם העסק שלכם עונה על הקריטריונים הנדרשים או לא. זאת, לעומת עבודה עם לקוחות מזדמנים או ספקים פרטיים שעשויים לשנות את הקריטריונים הרלוונטיים מבחינתם מבלי להודיע על כך מראש, או אפילו מבלי להגדיר לעצמם במפורש מה בדיוק טיב השירות או המוצר שהם זקוקים לו.</li>
            <li><b>יציבות –</b> הודות לכך, עבודה עם גוף ציבורי יכולה לאפשר לעסק שלכם לצמוח באופן יציב ומשמעותי, להרחיב את המחזור העסקי שלו ולהגדיל את הכנסותיו ואת רווחיו. היציבות הכרוכה בכך תאפשר לכם לתכנן לטווח הארוך ולקדם את העסק שלכם באופן שלעתים רק עבודה עם גופים גדולים יכולה לאפשר.</li>
            <li><b>פוטנציאל –</b>ההצלחה שלנו מדברת בעד עצמה. כיום אנו החברה המובילה בישראל בתחומנו, עם אלפי לקוחות מרוצים שיכולים להעיד על הצמיחה העסקית שהשירות שלנו איפשר להם להגיע אליה. </li>
            </ul><br/>
            <b>מכרזים עיריית באר שבע – חיפוש יעיל וממוקד</b><br/><br/>
            ביטויי ומילות חיפוש כגון מכרזים באר שבע הם רק דוגמה לאופן שבו חיפוש ממוקד יוכל לסייע לכם לאתר את ההזדמנויות העסקיות הרלוונטיות ביותר עבורכם. ישנה חשיבות מרבית לעבודה עם גוף מקצועי שזהו תחום התמחותו, ושיעמיד לרשותכם את מיטב אנשי המקצוע במטרה לוודא שתקבלו את השירות, התוצר והתוצאות שאתם מעוניינים בהם.<br/></br/>
            התמהיל של מקצועיות, ידע וניסיון של יותר מארבעה עשורים ומחויבות אישית לכל אחד מלקוחותינו, הופך אותנו לבחירה הנכונה ביותר מבחינתכם בכל הנוגע לאיתור ולקיטלוג הזדמנויות עסקיות – שיאפשרו לכם להתקדם מבחינה עסקית.<br/><br>
            <b><a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">אנו ביפעת מכרזים נשמח לעמוד לשירותכם</a>. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "hodHasharon",
    name: "מכרזים עיריית הוד השרון",
    title: "מכרזים עיריית הוד השרון: פרסומים שוטפים ועדכניים | יפעת מכרזים",
    description:
      "יפעת מכרזים היא החברה המובילה בישראל, המספקת פרסומים שוטפים ועדכניים של מכרזי עיריית הוד השרון ומציעה שירות ליווי אישי של סוכן חכם. למידע נוסף לחצו כאן",
    text: `<b>איתור וקיטלוג מכרזים פומביים או בלעדיים והתקשרויות פטורות ממכרז, עשויים להיות תנאי הכרחי לצמיחה עסקית בשוק התחרותי של ימינו. רק מידע אמין, נגיש ועדכני בזמן אמת יוכל להבטיח שלא תחמיצו הזדמנויות עסקיות החיוניות לקידום העסק שלכם, להרחבת המחזור העסקי שלכם ולהגדלת ההכנסות שלכם בטווח הארוך.</b><br/><br/>
            <b>עדכונים בזמן אמת על מכרזים עיריית הוד השרון</b><br/><br/>
            הדבר יכול להיות משמעותי במיוחד כאשר מדובר על התקשרות עם עירייה או רשות מקומית ספציפית, שעליכם לדעת אילו אפשרויות לעבודה איתה פתוחות בפניכם בכל רגע נתון והאם העסק שלכם עומד בתנאים הנדרשים.<br/><br/>
            בדיוק לשם כך הוקמה יפעת מכרזים לפני למעלה מארבעים שנה, כשהחזון המנחה אותה הוא מתן השירות המיטבי באיתור ובהנגשה של המידע לגבי הזדמנויות עסקיות שונות הפתוחות בפניכם. כיום, אנו הגורם המקצועי המוביל בישראל בתחום של איתור מכרזים וסיווגם.<br/><br/>
            <b>כיצד נעזור לכם באיתור מכרזים עיריית הוד השרון?</b><br/><br/>
            אלו כמה ממאפייני השירות שאנו מציעים:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>סיווג ומיון –</b> אנשי המקצוע שלנו יוכלו לאתר ולקטלג עבורכם הזדמנויות עסקיות רלוונטיות בדמות מכרזים פומביים, סגורים, חיצוניים ועוד או התקשרויות פטורות ממכרז, ולמיין את התוצאות בהתאם לדרישות שלכם.</li>
            <li><b>חיפוש ממוקד –</b> באמצעות שימוש בביטויי מפתח דוגמת הוד השרון מכרזים תוכלו למקד את החיפוש שלכם, ולבקש מאיתנו להציג בפניכם רק התקשרויות עסקיות הרלוונטיות לעירייה המבוקשת, במקרה זה עיריית הוד השרון.</li>
            </ul><br/><br/>
            <b>יתרונות נוספים בלהיעזר בנו לחיפוש מכרזים עיריית הוד השרון</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>התאמה אישית –</b> עשרות המידענים ומנהלי תיקי הלקוחות של יפעת מכרזים יתאימו את השירות באופן אישי ומדויק לצרכים העסקיים הייחודיים שלכם.</li>
            <li><b>הנגשה מלאה –</b> אנו נדאג שתקבלו את כל המידע הרלוונטי על מנת לוודא שלא תחמיצו שום מכרז או התקשרות הרלוונטיים מבחינתכם ושיכולים להוות עבורכם הזדמנות עסקית רצויה.</li>
            <li><b>ליווי מקצועי לאורך כל הדרך –</b> יפעת מכרזים מאמינה בתודעת שירות ברמה הגבוהה ביותר ובליווי מקצועי אדיב ומסור של כל אחד מלקוחותינו בדרך להזדמנות העסקית הבאה שלו.</li>
            </ul><br/><br/>
            <b>כל מה שרציתם לדעת על מכרזים עיריית הוד השרון – בלחיצת כפתור אחת</b><br/><br/>
            אם אתם בעלי עסקים, מנהלי מכירות ופיתוח עסקי, מומחי נדל"ן או מנהלי לוגיסטיקה ורכש המעוניינים למשל באיתור וסיווג מלאים, עדכניים ומהימנים של תוצאות חיפוש, תוך שימוש בביטויי מפתח כמו מכרזים עיריית הוד השרון – השירות שאנו מציעים יענה במדויק על דרישתכם.<br/></br/>
            היכולת של אנשי המקצוע שלנו לספק לכם מידע אמין, עדכני וממוקד בזמן אמת ביותר מ-70 מקצועות בתחומים שונים במשק, ולמיין אותו בהתאם לתנאי כל מכרז ולדרישות הספציפיות שלכם – הופך את השירות שאנו מציעים למפתח להצלחה עבור מגוון רחב של עסקים קטנים, בינוניים וגדולים כאחד.<br/><br>
            <b>מה הופך אותנו למובילים באיתור מכרזים עיריית הוד השרון?</b><br/><br/>
            ישנם מספר גורמים עיקריים לכך שיפעת מכרזים היא המובילה בתחום של איתור וקיטלוג מכרזים והתקשרויות בשלושת העשורים שבהם היא פועלת:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>ידע וניסיון –</b> כחברה המובילה בתחומה בעשורים האחרונים, יש לעשרות המידענים ומנהלי תיקי הלקוחות שאנו מעסיקים ידע וניסיון עשירים המאפשרים להם להעניק לכל לקוח את השירות המיטבי באופן המותאם במדויק לצרכיו.</li>
            <li><b>סטנדרטים מקצועיים גבוהים –</b> בכל הנוגע למקצועיות, אנו מאמינים בהקפדה על הסטנדרטים הגבוהים והמחמירים ביותר בתחום בארץ ובעולם ולכן מעסיקים אך ורק את מיטב אנשי המקצוע בתחום.</li>
            <li><b>מצוינות כערך מרכזי –</b> עם רקורד עשיר של הצלחה מוכחת, אנו עושים הכול כדי שלקוחותינו יקבלו את המידע המלא, העדכני והזמין ביותר שיאפשר להם לקדם את העסק שלהם באופן מיטבי.</li>
            </ul><br/><br/>
            <b>רוצים לדעת עוד על מכרזים עיריית הוד השרון?</b><br/><br/>
            התוצאות יסווגו בהתאם לעירייה או לרשות המקומית הרלוונטיות עבורכם, על מנת לאפשר לכם להגיש מועמדות למכרז או להתקשרות שיענו על צרכיכם העסקיים באופן המיטבי.<br/><br/>
            <b><a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">אנו ביפעת מכרזים נשמח לעמוד לשירותכם</a>. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "nativot",
    name: "מכרזים עיריית נתיבות",
    title: "מכרזים עיריית נתיבות: פרסום מגוון הזדמנויות עסקיות | יפעת מכרזים",
    description:
      "מכרזים של עיריית נתיבות עשויים להיות ההזדמנות העסקית שתאפשר לכם להגדיל את ההכנסות של העסק. יפעת מכרזים מציעים פרסום עדכני ושוטף של מכרזי עיריית נתיבות, לחצו כאן",
    text: `<b>אחד היתרונות המרכזיים הטמונים בפנייה לגורם מקצועי שתחום התמחותו העיקרי הוא איתור וקיטלוג מכרזים פומביים ובלעדיים והתקשרויות פטורות ממכרז, הוא היכולת לקבל מידע עדכני, זמין ונגיש בזמן אמת על ההזדמנויות העסקיות הרלוונטיות ביותר מבחינתכם.</b><br/><br/>
            <b>איפה תמצאו הכול על מכרזים עיריית נתיבות?</b><br/><br/>
            בדיוק לשם כך הקמנו לפני למעלה מארבעה עשורים את <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">יפעת מכרזים – הגוף המוביל בישראל</a> באיתור, סיווג והנגשה של מכרזים פומביים, סגורים וחיצוניים ושל הזדמנויות עסקיות אחרות. עשרות המידענים ומנהלי תיקי הלקוחות שלנו יתאימו במדויק ובאופן אישי לצרכיכם העסקיים הייחודיים את התוצאות, וימיינו אותן בהתאם לחיפוש הממוקד שרציתם ולתנאי כל מכרז.<br/><br/>
            <b>למה כדאי להיעזר בנו לאיתור מכרזים עיריית נתיבות?</b><br/><br/>
            אלו כמה מהערכים המרכזיים המנחים אותנו כארגון לאורך שלושת העשורים שבהם אנו פועלים:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>אמינות –</b> אנו מקפידים להציג אך ורק את המידע המקיף, האמין והמדויק ביותר על מנת לוודא שלקוחותינו יוכלו לממש את ההזדמנויות העסקיות שהם מעוניינים בהן באופן מיטבי.</li>
            <li><b>עדכניות –</b> אנשי המקצוע שלנו יספקו לכם מידע עדכני בזמן אמת על מנת שלא תחמיצו מכרז או התקשרות פטורה ממכרז הרלוונטיים לצמיחה עסקית עתידית שלכם.</li>
            <li><b>התאמה אישית –</b> השירות שלנו מותאם במדויק לצרכיו האישיים של כל לקוח, על מנת לספק לו תוצאות מיטביות ולענות באופן מלא על דרישותיו.</li>
            </ul><br/>
            <b>מכרזים עיריית נתיבות – חיפוש מותאם אישית</b><br/><br/>
            דוגמה מובהקת לחיפוש המותאם באופן אישי לצרכים הספציפיים שלכם, היא חיפוש מכרזים והתקשרויות עם רשות מקומית או עירייה מסוימת. כך, למשל, תוכלו לבקש מאיתנו לבחון ביטוי או מילות חיפוש דוגמת מכרזים נתיבות על מנת לקבל את כל התוצאות הרלוונטיות עבורכם.<br/></br/>
            <b>כיצד תקבלו את כל המידע על מכרזים עיריית נתיבות?</b><br/><br/>
            כך זה מתבצע:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>חיפוש מדויק –</b> אנשי המקצוע שלנו יבצעו את החיפוש המותאם באופן המדויק ביותר לצרכים העסקיים הייחודיים שלכם, על מנת לוודא שתקבלו מידע זמין, עדכני ואמין על ההתקשרויות העסקיות שאתם מעוניינים בהן.</li>
            <li><b>מיון נכון של התוצאות –</b> את התוצאות נמיין ונסווג בהתאם לדרישות המכרזים השונים וכמובן, לחיפוש שביקשתם.</li>
            <li><b>הנגשה מלאה ונוחה של המידע –</b> לבסוף, נספק לכם את המידע באופן פשוט, ברור ונגיש שיאפשר לכם להתנהל בנוחות ולהגיש מועמדות בזמן אמת לכל מכרז או התקשרות פטורה ממכרז הרלוונטיים מבחינתכם.</li>
            </ul><br/>
            <b>הפוטנציאל העסקי של מכרזים עיריית נתיבות</b><br/><br/>
            הודות לשירות שאנו מספקים, תוכלו לממש את ההזדמנויות העסקיות החיוניות לצמיחת העסק שלכם, להגדלת המכירות שלו ולהתרחבותו בטווח הארוך.<br/><br/>
            ביטוי חיפוש כגון מכרזים עיריית נתיבות יכול, כאמור, להיות המפתח להצלחה עסקית עבורכם, אם אתם נמנים על מגוון סוגי הלקוחות שנעזרים בשירות שלנו כבר יותר משלושים שנה. <br/><br/>
            <b>מכרזים עיריית נתיבות – למי זה מיועד?</b><br/><br/>
            למי השירות שלנו יכול להיות רלוונטי?<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>בעלי עסקים –</b> אם בבעלותכם עסק קטן, בינוני או גדול תוכלו לבצע חיפוש מושכל בעזרתנו, לקבל מידע אמין ומתמשך בזמן אמת ולזכות במכרזים או בהתקשרויות הרלוונטיים לצמיחה עסקית עתידית שלכם.</li>
            <li><b>מנהלי מכירות ופיתוח עסקי –</b> כמנהלי פיתוח עסקי ומכירות, השירות שלנו יספק לכם את הכלים המקצועיים הנדרשים להזנקת העסק שאתם משתייכים אליו לשלב הבא של פעילות עסקית, להגדלת מחזור מכירותיו ולהתקדמות מקצועית משמעותית שלכם במסגרתו.</li>
            <li><b>מומחי נדל"ן –</b> מגוון המכרזים – הפומביים, הסגורים והחיצוניים, כמו גם ההתקשרויות הפטורות ממכרז, ברשויות המקומיות ובעיריות השונות, מספקים הזדמנויות פז עסקיות עבור מומחי נדל"ן. אם אתם משתייכים לקטגוריה זו, השירות שלנו רלוונטי גם עבורכם.</li>
            <li><b>מנהלי רכש ולוגיסטיקה –</b> אם זהו תחום התמחותכם, המידע המקיף והנגיש שלנו יוכל לאפשר לכם לממש הזדמנויות עסקיות הרלוונטיות לתחום הלוגיסטיקה והרכש ולקדם את הפעילות של העסק שאתם משתייכים אליו גם בתחומים הללו.</li>
            </ul><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "negevFields",
    name: "מכרזים מועצה אזורית שדות נגב",
    title: "מכרזים מועצה אזורית שדות נגב: פרסום מידע עדכני | יפעת מכרזים",
    description:
      "יפעת מכרזים בעלי ניסיון של יותר מ-40 שנה בפרסום מכרזים ויצירת הזדמנויות עסקיות, מציעים מידע עדכני ושוטף על פרסום מכרזי מועצה אזורית שדות נגב, לפרטים נוספים לחצו כאן",
    text: `<b>קבלת מידע אמין, עדכני ונגיש בזמן אמת על מכרזים פומביים, סגורים או חיצוניים או על התקשרויות פטורות ממכרז, היא תנאי חיוני להצלחה עסקית עבור עסק המעוניין בהתקשרות עם עירייה או מועצה מקומית או אזורית מסוימת.</b><br/><br/>
            <b>היכן תמצאו הכול על מכרזים מועצה אזורית שדות נגב?</b><br/><br/>
            בדיוק לשם כך הקמנו לפני למעלה מארבעה עשורים את יפעת מכרזים, המהווה כיום את הגורם המקצועי המוביל והמוערך ביותר בישראל בכל הנוגע לאיתור, סיווג והנגשה של מכרזים והזדמנויות עסקיות אחרות.<br/><br/>
            במאמר זה נציג לפניכם את השלבים השונים הכרוכים בחיפוש מכרזים והתקשרויות עם גוף ספציפי, כגון מכרזים מועצה אזורית שדות נגב, ונסביר כיצד תוכלו לקבל את השירות המיטבי בתחום זה באופן שיבטיח שלא תחמיצו הזדמנות שתאפשר לכם לקדם את העסק שלכם ולהגדיל את הכנסותיכם בטווח הבינוני והארוך כאחד.<br/><br/>
            <b>חיפוש ממוקד של מכרזים מועצה אזורית שדות נגב</b><br/><br/>
            עשרות המידענים ומנהלי תיקי הלקוחות של יפעת מכרזים יבצעו חיפוש המותאם במדויק לצרכים העסקיים הייחודיים שלכם ויציגו בפניכם תוצאות ביותר מ-70 מקצועות בענפים שונים במשק, הממוינות לפי תנאי המכרזים ודרישותיכם הספציפיות.<br/><br/>
            כך תוכלו לדעת האם העסק שלכם עונה על דרישות המכרז והאם כדאי לכם להגיש מועמדות אליו, ולצד זאת תדעו שלא החמצתם הזדמנות עסקית אחרת כגון התקשרות פטורה ממכרז, שגם היא יכולה לקדם את פעילותכם העסקית באופן משמעותי שיענה על החזון העסקי שלכם בטווח הארוך.<br/><br/>
            ניתן להציג את התוצאות כשהן מחולקות לפי קטגוריות שונות ולמקד אותן, כאמור, בעירייה, ברשות המקומית או במועצה האזורית הרלוונטיות ביותר מבחינתכם.<br/><br/>
            בחלק הבא של המאמר נלמד כיצד לעשות זאת.<br/><br/>
            <b>מכרזים מועצה אזורית שדות נגב – איך זה עובד?</b><br/><br/>
            בשלב הראשון, נבצע חיפוש מושכל בהתאם לדרישות הספציפיות שלכם, על מנת לוודא שתקבלו מידע מקיף, מלא וזמין בזמן אמת על כל הזדמנות עסקית שעשויה להיות רלוונטית עבורכם.<br/></br/>
            בשלב השני, נציג בפניכם את המידע באופן נוח, פשוט ונגיש כדי שתוכלו לעשות בו שימוש מיטבי מבלי להזדקק למיומנויות פענוח או לשליטה בצורות הצגה ויזואליות כאלו או אחרות.<br/><br>
            כך, לדוגמה, ביטוי מפתח כמו שדות נגב מכרזים יניב חיפוש מושכל, מקצועי ומדויק שיספק לכם את כל התוצאות הרלוונטיות בתחום של מכרזים פומביים, סגורים וחיצוניים או של התקשרויות פטורות ממכרז עם המועצה האזורית שדות נגב.<br/><br>
            אולם כיצד תדעו אם השירות שלנו רלוונטי עבורכם?<br/><br/>
            <b>מכרזים מועצה אזורית שדות נגב – למי זה מיועד?</b><br/><br/>
            בנוסף לבעלי עסקים, השירות שלנו רלוונטי בין היתר עבור:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>אנשי מקצוע בתחום הנדל"ן –</b> אם אתם מומחי נדל"ן הזקוקים למידע עדכני וזמין על ההזדמנויות הכדאיות ביותר מבחינתכם בענף, אנו ביפעת מכרזים יכולים לוודא שלא תחמיצו את המכרז או ההתקשרות שיניבו עבורכם רווחים עתידיים מקסימליים.</li>
            <li><b>מנהלים בתחומי הרכש והלוגיסטיקה –</b> תוכלו לקדם באופן משמעותי את הארגון שלכם באמצעותנו. המידע העדכני שנספק לכם על המכרזים וההתקשרויות העונים על צרכיכם העסקיים בצורה הטובה ביותר, יכול לפתוח עבורכם פתח להצלחה עסקית עתידית של הארגון שלכם.</li>
            <li><b>מנהלים בתחומי המכירות והפיתוח העסקי –</b> גם מנהלי פיתוח עסקי ומכירות יכולים לצאת נשכרים במיוחד מקבלת מידע אמין, מקצועי וזמין המתעדכן תדירות על מכרזים פומביים או בלעדיים ועל התקשרויות פטורות ממכרז שיכולות לקדם את הארגון שלהם, להגדיל את המכירות שלו ולפתח אותו מבחינה עסקית.</li>
            </ul><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "ramatHasharon",
    name: "מכרזים עיריית רמת השרון",
    title:
      "מכרזים עיריית רמת השרון: פרסום מגוון הזדמנויות עסקיות | יפעת מכרזים",
    description:
      "יפעת מכרזים מרכזת עבורכם את הפרסומים העדכניים ביותר למכרזי עיריית רמת השרון. באתר תוכלו להנות משירות של סוכן חכם, ליצור הזדמנויות עסקיות ולהגדיל את ההכנסה",
    text: `<b>התקשרות עם עירייה כמו עיריית רמת השרון שתהיה כדאית ומתגמלת באופן ארוך טווח עבורכם מבחינה עסקית, צריכה לענות על שני קריטריונים עיקריים:</b><br/><br/>
            <b>מכרזים עיריית רמת השרון – כדאיות ורלוונטיות</b><br/><br/>
             <ul style="padding-right: 5rem">
            <li><b>רלוונטיות –</b> על המכרז הפומבי, הסגור או החיצוני שתתמודדו בו או ההתקשרות הפטורה ממכרז שתגישו אליה מועמדות, להיות כאלו שאתם עומדים בכל התנאים והדרישות שלהם.</li>
            <li><b>כדאיות –</b> על המכרז או ההתקשרות הללו לענות על הצרכים העסקיים שלכם באופן מיטבי ולאפשר לכם לממש את היעדים שהצבתם לעצמכם, כגון הגדלת המחזור העסקי, המכירות והרווחים שלכם תוך פרק זמן מסוים.</li>
            </ul><br/>
            <b>כיצד תדעו הכול על מכרזים עיריית רמת השרון?</b><br/><br/>
            על מנת להגיש מועמדות למכרזים הרלוונטיים והכדאיים ביותר עבורכם, עליכם לדעת עליהם בזמן אמת. בדיוק לשם כך הקמנו לפני למעלה מארבעים  שנה את <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">יפעת מכרזים – הגוף המוביל והמוערך בישראל</a> בתחום של איתור וקיטלוג מכרזים פומביים ובלעדיים והתקשרויות פטורות ממכרז. <br/><br/>
            שימוש בביטוי חיפוש כמו רמת השרון מכרזים יוכל להניב עבורכם תוצאות שיתעדכנו תדירות, שאותן נסווג בהתאם לדרישות המכרז ובאופן הממוין לפי הרשות המקומית הרלוונטית ומותאם כמובן במדויק לדרישות החיפוש שלכם.<br/><br/>
            <b>מכרזים עיריית רמת השרון – כל המידע במקום אחד</b><br/><br/>
            אלו כמה מהיתרונות הבולטים שבעבודה עם יפעת מכרזים בכל הנוגע לאיתור, סיווג והנגשה של מידע על הזדמנויות עסקיות הרלוונטיות מבחינתכם:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>חיפוש איכותי –</b> כחברה המובילה בישראל בתחומה, אנו נספק לכם את המידע המקיף, העדכני והמדויק ביותר על ההזדמנויות העסקיות הרלוונטיות עבורכם, באופן המותאם במדויק לצרכים העסקיים שלכם.</li>
            <li><b>פילוח מדויק –</b> אנו נמיין ונסווג את תוצאות החיפוש שביצענו בהתאם לתנאי כל מכרז, לדרישות שלכם ובאופן שיכלול למעלה מ-70 מקצועות בענפים שונים במשק, על מנת לוודא שהמידע הרלוונטי עבורכם יגיע אליכם בזמן אמת.</li>
            </ul><br/>
            <b>למה כדאי להיעזר בנו לאיתור מכרזים עיריית רמת השרון?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>הנגשה מרבית –</b> אנו נציג את המידע בפניכם באופן נגיש, נוח ופשוט לשימוש, שלא ידרוש מכם להתמצא בנבכי תצוגה מקצועית או ויזואלית כזו או אחרת.</li>
            <li><b>ניסיון ומקצועיות –</b> עשרות מנהלי תיקי הלקוחות והמידענים שלנו ניחנו בניסיון רב שנים ובידע ומיומנות מקצועיים מהשורה הראשונה בתחום, ומקפידים על הסטנדרטים המקצועיים המחמירים ביותר במטרה לספק לכם את השירות והתוצר הטובים ביותר בענף.</li>
            <li><b>רקורד של הצלחה מוכחת –</b> עם למעלה מארבעים שנה כחברה המובילה בישראל באיתור וקיטלוג מכרזים, ההישגים של יפעת מכרזים מדברים בעד עצמם. נשמח לעמוד גם לשירותכם.</li>
            </ul><br/>
            <b>מכרזים עיריית רמת השרון – תוצאות רלוונטיות ועדכניות</b><br/><br/>
            שימוש בביטוי חיפוש דוגמת רמת השרון מכרזים יוכל, כאמור, לפתוח פתח להצגת המידע הרלוונטי, הזמין והנגיש ביותר עבורכם כדי להבטיח שלא תחמיצו את ההזדמנות העסקית הבאה שלכם.<br/></br/>
            <b>מהם סוגי מכרזים עיריית רמת השרון?</b><br/><br/>
            אלו כמה מסוגי המכרזים וההתקשרויות שהשירות שלנו יוכל לספק לכם מידע עדכני ומקיף בזמן אמת לגביהם:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מכרזים פומביים –</b> מכרז פומבי מוגדר באתר הסוכנות לעסקים קטנים ובינוניים במשרד הכלכלה והתעשייה, בתור "מכרז שמקבל פומביות בדרך של פרסום בתקשורת ובאתרי החברות ומופנה כלפי כלל הציבור הרלוונטי לאותה התקשרות".</li>
            <li><b>מכרזים סגורים –</b> מכרז סגור הוא מכרז שבו הגורם המוציא אותו פונה למציעים ספציפיים שנבחרו על ידו מראש. ניתן לקיים מכרז סגור בתנאים המוגדרים מראש.</li>
            <li><b>התקשרויות הפטורות ממכרז –</b> התקשרות הפטורה מהחובה לקיים מכרז היא כזו העומדת בתנאים שהוגדרו מראש, כגון שווי כספי שאינו עולה על 50,000 שקלים חדשים, שחשוב לבצעה בדחיפות תוך שני ימי עבודה או פחות כדי למנוע נזק ממשי ועוד.</li>
            </ul><br/>
            <b>רוצים לדעת על מכרזים עיריית רמת השרון?</b><br/><br/>
            אם אתם בעלי עסק קטן, בינוני או גדול והייתם רוצים להגדיל את מכירותיו ורווחיו באופן משמעותי וארוך טווח, כדאי שתשקלו לפנות אלינו.<br/><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "sakhnin",
    name: "מכרזים עיריית סכנין",
    title: "מכרזים עיריית סכנין: פרסום עדכני של מכרזים פומביים | יפעת מכרזים",
    description:
      "יפעת מכרזים מרכזת עבורכם את המכרזים של עיריית סכנין המתעדכנים באופן שוטף. הגשת מועמדות למכרז של עיריית סכנין מייצרת הזדמנויות עסקיות שיגדילו את ההכנסות, לפרטים",
    text: `<b>קשה להפריז בחשיבות הטמונה בקבלת מידע אמין, מדויק, מקיף ועדכני בזמן אמת על מכרזים פומביים ובלעדיים ועל התקשרויות פטורות ממכרז שיוכלו לקדם את העסק שלכם לשלב הבא ולהגדיל באופן משמעותי את המחזור העסקי שלו, את רווחיו ואת הצלחתו ארוכת הטווח</b><br/><br/>
            <b>כיצד תדעו הכול על מכרזים עיריית סכנין?</b><br/><br/>
            בדיוק משום כך חשוב לפנות לגורם מקצועי המתמחה באיתור, סיווג והנגשה של מכרזים (פומביים, סגורים וחיצוניים) ושל הזדמנויות עסקיות אחרות באופן שיבטיח שלא תחמיצו את ההתקשרות שתאפשר לכם לצמוח מבחינה עסקית.<br/><br/>
            יפעת מכרזים מובילה כבר למעלה מארבעה עשורים את תחום איתור וקיטלוג המכרזים בישראל. במאמר זה המומחים של יפעת מכרזים יספרו לכם חמישה דברים שחשוב שתדעו עלינו, ידגימו כיצד תוכלו לבצע חיפוש ממוקד על פי הביטוי הספציפי עיריית סכנין מכרזים ויסבירו כיצד תוכלו ליצור איתנו קשר.<br/><br/>
            <b>מה היתרונות בלתת לנו לחפש מכרזים עיריית סכנין?</b><br/><br/>
            אלו שני דברים שחשוב שתדעו על יפעת מכרזים לפני שתתחילו לעבוד איתנו:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>הערך הארגוני המנחה אותנו הוא מקצועיות –</b> כגוף העומד בסטנדרטים המקצועיים המחמירים ביותר בתחום, נוכל לספק לכם מידע אמין ומלא בזמן אמת על ההזדמנויות העסקיות שיוכלו לעזור לכם להתקדם לשלב הבא.</li>
            <li><b>יש לנו רקורד של למעלה מ-30 שנות הצלחה מוכחת –</b> כחברה המובילה את תחום איתור וקיטלוג המכרזים בישראל במשך יותר משלושה עשורים, ההישגים שלנו מדברים בעד עצמם.</li>
            </ul><br/>
            <b>עוד דרכים שבהן נסייע לכם לאתר מכרזים עיריית סכנין</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>אנו מחויבים לספק לכל לקוח את המידע העדכני והנגיש ביותר –</b> היתרון בעבודה איתנו טמון בכך שתוכלו לקבל מידע מקיף ומלא בזמן אמת על כל המכרזים וההתקשרויות העסקיות שיוכלו לקדם את העסק שלכם, להרחיב את פעילותו ולהגדיל את רווחיו.</li>
            <li><b>יש לנו רקורד של למעלה מ-30 שנות הצלחה מוכחת –</b> אנו מציעים לכם חיפוש שיותאם במדויק לדרישות שלכם, ויאפשר לכם להתמודד במכרזים שהעסק שלכם עונה על דרישותיהם מצד אחד, והעונים על הצרכים העסקיים שלכם מצד שני.</li>
            <li><b>הצוות שלנו מורכב ממיטב אנשי המקצוע בתחום –</b> אנו מעסיקים אך ורק את מיטב המידענים ומנהלי תיקי הלקוחות בענף, על מנת לוודא שכל אחד ואחד מלקוחותינו יקבל את השירות והתוצרים הטובים ביותר שיש לתחום להציע.</li>
            </ul><br/>
            <b>מה הפוטנציאל העסקי של מכרזים עיריית סכנין?</b><br/><br/>
            עבודה עם גורם מקצועי המתמחה באיתור, סיווג והנגשה של מכרזים והתקשרויות, תאפשר לכם בין היתר:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>להיחשף לאלפי הזדמנויות עסקיות חדשות –</b> ממכרזים פומביים או בלעדיים ועד התקשרויות פטורות ממכרז, יפעת מכרזים תאתר עבורכם מגוון עשיר של הזדמנויות עסקיות ראויות שיאפשרו לעסק שלכם לצמוח.</li>
            <li><b>להגדיל את המכירות וההכנסות שלכם –</b> באמצעות איתור ההזדמנויות הרלוונטיות ביותר מבחינתכם, תוכלו להגדיל את מחזור העסק שלכם וליהנות ממכירות והכנסות גבוהות באופן משמעותי יותר מאלו שידעתם בעבר.</li>
            <li><b>לתכנן קדימה –</b> התקשרות יציבה עם גופים ציבוריים תאפשר לכם לתכנן את הצמיחה העסקית שלכם שנים קדימה ולפתח את העסק שלכם באופן הדרגתי המוכוון לטווח הארוך.</li>
            </ul><br/>
            <b>מכרזים עיריית סכנין – איך תבצעו חיפוש נכון?</b><br/><br/>
            ישנם מספר שלבים לביצוע חיפוש המותאם במדויק לצרכים העסקיים הייחודיים שלכם. כך, למשל, חיפוש ביטוי כגון מכרזים עיריית סכנין יוכל להניב עבורכם תוצאות במגוון רחב של למעלה מ-70 מקצועות בענפי המשק השונים, שאותן נמיין לפי דרישות כל מכרז.<br/></br/>
            אנשי המקצוע שלנו כאן כדי לוודא שתדעו מבעוד מועד על כל מכרז – פומבי, סגור או חיצוני – וכל התקשרות פטורה ממכרז של עיריית סכנין, על מנת שתוכלו להגיש אליהם מועמדות ולמצות את ההזדמנויות העסקיות העומדות בפניכם. <br/><br>
            <b><a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">אנו ביפעת מכרזים נשמח לעמוד לשירותכם</a>. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "freight",
    name: "מכרזי הובלות",
    title:
      "מכרזי הובלות: מאגר מידע עדכני ליצירת הזדמנויות עסקיות | יפעת מכרזים",
    description:
      "יפעת מכרזים מרכזת עבורכם את הפרסומים העדכניים ביותר למכרזי הובלות: הובלה במשאיות, הובלת מזון בקירור ועוד. מוזמנים להכנס לאתר ולהנות משירות של סוכן חכם>>",
    text: `<b>אם יש בבעלותכם חברת הובלות ואתם מעוניינים לקדם אותה לשלב הבא של צמיחה עסקית, כדאי שתדעו מבעוד מועד על ההזדמנויות הטמונות בהתקשרות דרך מכרז עם גופים שונים. בדיוק לשם כך הוקמה יפעת מכרזים – החברה המובילה כבר למעלה משלושים שנה את תחום האיתור, הסיווג וההנגשה של מכרזים פומביים, סגורים וחיצוניים ושל התקשרויות פטורות ממכרז בישראל.</b><br/><br/>
            <b>אצלנו תמצאו את כל המידע על מכרזי הובלות - </b><br/><br/>
            עם עשרות מידענים ומנהלי תיקי לקוחות המתמחים בהתאמה אישית ומדויקת של החיפוש, השירות והתוצאות לצרכים העסקיים הייחודיים של כל לקוח ולקוח, אנו נספק לכם מידע מקיף, מדויק ועדכני בזמן אמת על כל ההזדמנויות העסקיות העומדות בפניכם.<br/><br/>
            במאמר זה <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">המומחים של יפעת מכרזים</a> יסבירו מהם מכרזי הובלות ומה הפוטנציאל הטמון בהם, מהן שתי הדרכים שבאמצעותן ניתן לבצע חיפוש ולסווג את התוצאות, אילו שירותים אנו מציעים וכיצד תוכלו לבצע חיפוש מכרזי הובלות באופן הנכון ביותר.<br/><br/>
            <b>מה חשוב שתדעו על מכרזי הובלות?</b><br/><br/>
            מכרז הובלות הוא למעשה הדרך התקנית שבאמצעותה גוף החייב במכרז ייצור התקשרות עסקית מתמשכת או זמנית עם חברת הובלות העומדת בתנאים שהגדיר לעצמו.<br/></br/>
            אם יש בבעלותכם חברה קטנה, בינונית או גדולה בתחום ההובלות העומדת בתנאי מכרז מסוים, תוכלו להתמודד עליו ואם תזכו, להניח את התשתית להתקשרות עסקית מתמשכת עם הגוף שפרסם את המכרז, שעשויה להגדיל את המחזור העסקי שלכם, את הכנסותיכם ואת רווחיכם בטווח הקצר והארוך כאחד.<br/><br>
            בדיוק לשם כך יש לבצע חיפוש מדויק המותאם לצרכים שלכם, ולקבל את המידע המקיף, הנגיש והעדכני ביותר שאתם זקוקים לו כדי לוודא שלא תחמיצו את ההזדמנות העסקית הבאה שלכם. <br/><br/>
            <b>מכרזי הובלות – איך נסווג את התוצאות?</b><br/><br/>
            ישנן שתי דרכים עיקריות שבאמצעותן ניתן למיין את המכרזים הפומביים, הסגורים והחיצוניים ואת ההתקשרויות הפטורות ממכרז הרלוונטיות עבורכם:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מכרזים לפי גופים –</b> ניתן לסווג את המכרזים לפי הגופים שפרסמו אותם, ולחלק אותם לדוגמה למכרזי ממשלה, מכרזים של משרד ממשלתי מסוים (כגון משרד הביטחון, משרד החינוך וכו'), מכרזים של גוף ציבורי מסוים (כגון מכרזי מנהל מקרקעי ישראל ומכרזי חברת החשמל ) או מכרזים של שירות המדינה.</li>
            <li><b>מכרזים לפי קטגוריות –</b> לחלופין, ניתן לסווג את המכרזים לפי תחומים. למשל, למכרזי בנייה, מכרזי מקרקעין, מכרזי עבודות חשמל וכמובן, מכרזי הובלות. אצלנו תוכלו למצוא מכרזים והתקשרויות ב-74 תחומים שונים, באופן שיבטיח שתהיו מעודכנים בזמן אמת בהזדמנויות העסקיות הרלוונטיות מבחינתכם.</li>
            </ul><br/>
            <b>אילו שירותים אנו מציעים בחיפוש מכרזי הובלות?</b><br/><br/>
            ישנם מספר מוצרים ושירותים שיפעת מכרזים מציעה ללקוחותיה על מנת לוודא שיקבלו את התוצאה המיטבית מבחינתם:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>ייעוץ וליווי במכר –</b> הצוות שלנו, המורכב ממיטב אנשי המקצוע בתחום המידענות וניהול תיקי הלקוחות, מתמחה באיתור, סיווג והנגשה של כל המידע הרלוונטי עבורכם על מכרזים בתחומים שאתם מעוניינים בהם ומטעם גופים שברצונכם לעבוד איתם.</li>
            <li><b>סוכן חכם –</b> כלי המידע המתקדם שאנו מציעים יבצע עבורכם חיפוש מדויק של מכרזים באופן שיניב אך ורק תוצאות רלוונטיות, על בסיס מילות החיפוש שתגדירו. כך תוכלו לקבל מידע מלא, מקיף ועם זאת ממוקד אך ורק בהתאם לסוג המכרזים וההתקשרויות שאתם מחפשים.</li>
            <li><b>TendersMobi –</b> אפליקציית המכרזים הייעודית שלנו תאפשר לכם לקבל ישירות לנייד את כל המכרזים וההזדמנויות העסקיות בתחומכם, ממוינים לפי 74 תחומים במשק.</li>
            </ul><br/>
            <b>מכרזי הובלות – איך תעשו את זה נכון?</b><br/><br/>
            כדי ליהנות מכל השירותים שאנו מציעים, כל שעליכם לעשות הוא לפנות אלינו.<br/><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "lottery",
    name: "מפעל הפיס מכרזים",
    title: "מפעל הפיס מכרזים: מגוון הזדמנויות עסקיות עדכניות | יפעת מכרזים",
    description:
      "יפעת מכרזים מרכזת עבורכם את כל הפרסומים העדכניים של מכרזי מפעל הפיס ומציעה ליווי אישי של סוכן חכם. למידע על המכרזים המתפרסמים ויצירת הזדמנויות עסקיות לחצו כאן",
    text: `<b>מכרזים של מפעל הפיס עשויים להיות ההזדמנות העסקית שתאפשר לכם להגדיל את מחזור העסק שלכם, את המכירות שלכם ואת רווחיכם בטווח הקצר, הבינוני והארוך כאחד. אולם על מנת לוודא שתהיו מעודכנים בזמן אמת בכל המכרזים וההזדמנויות העסקיות שמפעל הפיס יכול להציע לכם, עליכם להיעזר בגורם המקצועי המוביל בישראל בתחום.</b><br/><br/>
            <b>כיצד תדעו הכול על מפעל הפיס מכרזים?</b><br/><br/>
            יפעת מכרזים הוקמה לפני למעלה מארבעים  שנה וכיום נחשבת <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">לחברה המובילה בישראל באיתור וקיטלוג מכרזים</a> פומביים, סגורים וחיצוניים והתקשרויות פטורות ממכרז.<br/><br/>
            אלו רק חלק מהשירותים שאנו מציעים ללקוחותינו:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>חיפוש המותאם במדויק עבורכם –</b> עשרות המידענים ומנהלי תיקי הלקוחות שלנו מתמחים בלהתאים את החיפוש באופן אישי ומדויק לצרכים העסקיים הייחודיים שלכם.</li>
            <li><b>סוכן חכם שיספק לכם מידע מקיף וממוקד –</b> איתנו, תוכלו לדוגמה לבצע חיפוש לפי הביטוי מפעל הפיס מכרזים ולקבל רשימה מלאה, מקיפה ויסודית של המכרזים וההזדמנויות העסקיות הרלוונטיות מצד אחד, ולדעת שכלי החיפוש המדויקים שלנו סיננו עבורכם תוצאות לא רלוונטיות מצד שני. </li>
            </ul><br/>
            <b>כיצד עוד נסייע לכם באיתור מפעל הפיס מכרזים?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>עדכונים שוטפים במייל –</b> המכרזים וההזדמנויות העסקיות הרלוונטיות יישלחו מספר פעמים ביום למייל שלכם, על מנת לוודא שלא תחמיצו אף הזדמנות.</li>
            <li><b>חלוקה ל-74 תחומים במשק –</b> המכרזים וההזדמנויות העסקיות שנשלח לכם ימוינו לפי תחומי התמחות שונים, כדי שתוכלו למצוא את ההזדמנויות הרלוונטיות ביותר בתחומכם. </li>
            <li><b>כל המידע בזמן אמת ישירות לנייד –</b> TrendsMobi, אפליקציית המכרזים הייעודית שלנו, תאפשר לכם לקבל ישירות למכשיר הטלפון הסלולרי שלכם את כל המכרזים וההזדמנויות העסקיות העדכניים ביותר בתחומכם, ממוינים ל-74 מקצועות שונים במשק. </li>
            </ul><br/>
            <b>שאלתם כבר את הסוכן החכם על מפעל הפיס מכרזים?</b><br/><br/>
            על מנת להישאר מעודכנים בכל המכרזים הפומביים והבלעדיים וההזדמנויות העסקיות שיענו באופן הטוב ביותר על הצרכים שלכם, מומלץ להפעיל את הסוכן החכם שלנו, להכניס את ההגדרות ומילות החיפוש הרלוונטיות מבחינתכם – ולהתחיל לחפש את ההזדמנויות העסקיות הבאות.<br/><br/>
            <b>מפעל הפיס מכרזים – לפתוח לכם עוד דלתות</b><br/><br/>
            יפעת מכרזים היא המובילה בתחום של איתור וקיטלוג מכרזים בישראל, הודות לשילוב שמאפיין אותה בין מספר יתרונות מרכזיים:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>כלי חיפוש מתקדמים –</b> הסוכן החכם שלנו יוודא שלא תחמיצו אפילו מכרז או הזדמנות עסקית אחת העונים על מילות החיפוש וההגדרות שלכם, ומצד שני ינטרל תוצאות לא רלוונטיות שעלולות להסתיר עבורכם את ההזדמנויות הרלוונטיות מבחינתכם.</li>
            <li><b>ידע מקיף ועדכני –</b> הצוות המקצועי שלנו מורכב מעשרות מידענים ומנהלי תיקי לקוחות המתמחים במתן שירות המותאם באופן אישי ומדויק לצרכיו העסקיים של כל לקוח, ובאיתור, סיווג והנגשה מקיפים, יסודיים ומדויקים של מכרזים והזדמנויות עסקיות.</li>
            </ul><br/>
            <b>מדוע אנו המקור המוביל לאיתור מפעל הפיס מכרזים?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>ניסיון וכישורים מקצועיים –</b> העובדה שאנו מובילים את תחום האיתור והקיטלוג של מכרזים והזדמנויות עסקיות בישראל כבר יותר מ-40 שנה, משמעה שאנו מביאים עימנו ניסיון וכישורים מקצועיים שיבטיחו שלא תחמיצו את ההזדמנות העסקית הבאה שלכם.</li>
            <li><b>מסירות ותודעת שירות –</b> הצוות שלנו כולו ניחן בתודעת שירות גבוהה ומעניק יחס אישי מסור, מקצועי ואכפתי לכל לקוח ולקוח.</li>
            <li><b>רקורד של הצלחה מוכחת –</b> ההישגים שצברנו בשלושת העשורים שבהם אנו מובילים בתחומנו מדברים בעד עצמם. איתנו, תוכלו לדעת שלחיפוש שלכם יהיו תוצאות. </li>
            </ul><br/>
            <b>מפעל הפיס מכרזים – הדרך שלכם לצמיחה</b><br/><br/>
            על מנת להיחשף להזדמנויות העסקיות הרלוונטיות, הכדאיות והמשתלמות ביותר עבורכם, תוכלו להבטיח את המשך הפעילות של העסק שבבעלותכם ויתרה מזאת, את הרחבתה בטווח הזמן הקצר, הבינוני והארוך כאחד. ההזדמנויות העסקיות שיפעת מכרזים תאתר עבורכם יאפשרו לכם להגדיל את מכירות העסק, את הכנסותיו ואת המחזור שלו ולתכנן את צמיחתו והתפתחותו שנים מראש.<br/><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "earthworks",
    name: "מכרזים לעבודות עפר",
    title: "מכרזי עבודות עפר: פרסום מכרזים ממשלתיים ופומביים | יפעת מכרזים",
    description:
      "יפעת מכרזים מרכזת עבורכם פרסום מכרזים לעבודות עפר המתעדכנים באופן שוטף: מכרזי עבודות עפר בצפון, מכרזי עבודות עפר ופיתוח ועוד. ליצירת הזדמנויות עסקיות לחצו כאן",
    text: `<b>אם יש בבעלותכם עסק המתמחה בעבודות עפר והייתם מעוניינים להגדיל את המחזור שלו, את מכירותיו ואת הכנסותיו – הדרך הטובה ביותר לעשות זאת היא לוודא שתקבלו בזמן אמת מידע מקיף, מדויק ועדכני על כל המכרזים וההזדמנויות העסקיות הרלוונטיות בתחומכם. </b><br/><br/>
            <b>איפה כדאי לכם לחפש מכרזים לעבודות עפר?</b><br/><br/>
            <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">יפעת מכרזים</a> הוקמה לפני למעלה מארבעים שנה וכיום מהווה את הגורם המקצועי המוביל והמוערך בישראל בתחום של איתור וקיטלוג מכרזים פומביים, סגורים או חיצוניים והתקשרויות פטורות ממכרז.<br/><br/>
            עשרות מנהלי תיקי הלקוחות והמידענים שלנו יוכלו להתאים במדויק ובאופן אישי את השירות לצרכים העסקיים הייחודיים שלכם, ולהבטיח שתדעו בזמן אמת על מכרזים לעבודות עפר שיוכלו לקדם אתכם מבחינה עסקית. <br/><br/>
            במאמר זה נסביר כיצד אנו עושים זאת.<br/><br/>
            <b>מדוע אנו מקור המידע המוביל על מכרזים לעבודות עפר?</b><br/><br/>
            ישנן מספר דרכים שבאמצעותן יפעת מכרזים מבטיחה שהשירות שהיא מספקת ללקוחותיה יענה על הסטנדרטים הקפדניים והמחמירים ביותר של איכות ומקצועיות בתחום האיתור, הסיווג וההנגשה של מכרזים והזדמנויות עסקיות:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>שימוש בטכנולוגיה המתקדמת ביותר –</b> באמצעות כלי החיפוש המדויקים שלנו, הסוכן החכם יוכל לוודא שתקבלו בזמן אמת ובאופן נגיש, פשוט ונוח את כל המידע על מכרזים והזדמנויות עסקיות בתחומכם, ולסנן עבורכם את כל התוצאות הלא רלוונטיות.</li>
            <li><b>העסקת מיטב אנשי המקצוע בתחום –</b> הצוות שלנו מורכב אך ורק מאנשי מקצוע בעלי ידע עשיר, מקיף ועדכני בתחום, תודעת שירות ברמה גבוהה ומחויבות לספק לכם את השירות הטוב ביותר ולהתאימו באופן מדויק לצרכיכם.</li>
            </ul><br/>
            <b>למה נוח לקבל מאיתנו עדכונים על מכרזים לעבודות עפר?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>הצגה נוחה ונגישה של המידע –</b> איתנו תוכלו לקבל ישירות למייל מספר פעמים ביום את כל המידע על הזדמנויות עסקיות בתחומכם – מה שיכול להגיע במצטבר ל-30,000 מכרזים והזדמנויות בשנה.</li>
            <li><b>מתן כלים בסוגי מדיה שונים –</b> אם אתם מעדיפים, למשל, לעבוד עם הנייד, אתם מוזמנים להוריד את TrendsMobi, אפליקציית המכרזים הייעודית שלנו, ולצפות דרכה בכל המכרזים וההזדמנויות כשהם מחולקים לפי 74 מקצועות שונים במשק. כך יתאפשר לכם להתמקד באופן נגיש וברור רק בהזדמנויות הרלוונטיות עבורכם.</li>
            </ul><br/>
            <b>למה נוח לקבל מאיתנו עדכונים על מכרזים לעבודות עפאילו סוגי מכרזים לעבודות עפר ישנם?</b><br/><br/>
            ישנם שני סוגים עיקריים של מכרזים בכל אחד מהתחומים השונים, ובכלל זאת בתחום של מכרזים לעבודות עפר:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מכרז פומבי –</b> מכרז שפורסם באופן פומבי בכלי התקשורת, באתר הגוף המפרסם וכיוצא בזאת, על מנת לאפשר לכל עסק רלוונטי להתמודד במסגרתו.</li>
            <li><b>מכרז בלעדי –</b> במקרים מסוימים שהוגדרו מראש, רשאי הגוף המקיים את המכרז לפנות ישירות לעסקים שהוא רוצה שיספקו לו את השירות שבו הוא מעוניין.</li>
            </ul>
            לצד זאת, ישנן גם התקשרויות פטורות ממכרז, במקרים מסוימים שאף הם הוגדרו מראש.<br/><br/>
            <b>שאלו את הסוכן החכם על מכרזים לעבודות עפר</b><br/><br/>
            באמצעות הסוכן החכם שלנו תוכלו לדעת מבעוד מועד על כל אחד מסוגי המכרזים הרלוונטיים עבורכם, על מנת לוודא שלא תחמיצו את ההזדמנות העסקית המשמעותית הבאה שתיקרה בדרככם.<br/></br/>
            <b>למי כדאי לאתר בעזרתנו מכרזים לעבודות עפר?</b><br/><br/>
            השירות שלנו מיועד בראש ובראשונה לבעלי עסקים – קטנים, בינוניים או גדולים – המעוניינים להגדיל את המחזור העסקי, המכירות והרווחים שלהם ולפתח את העסק שלהם בטווח המיידי והארוך.<br/><br/>
            התקשרות עם גופים ציבוריים שונים באמצעות מכרז יכולה לספק לכם את מרווח הנשימה הדרוש לתכנון עסקי שיניב צמיחה עסקית ארוכת טווח.<br/><br/>
            לצד בעלי עסקים, השירות שלנו רלוונטי גם למנהלי מכירות ופיתוח עסקי, למומחי נדל"ן ולמנהלי רכש ולוגיסטיקה המעוניינים לקדם את העסק שלהם או הפרויקטים שהם מעורבים בהם באמצעות קבלת מידע עדכני ומקיף בזמן אמת על הזדמנויות עסקיות שיוכלו להיות כדאיות מבחינתם.</br><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "jointsIsrael",
    name: "מכרזי ג'וינט ישראל",
    title: "מכרזי ג'וינט ישראל: מאגר פרסום מכרזים עדכני | יפעת מכרזים",
    description:
      "ארגון הג'וינט הוא ארגון צדקה יהודי אמריקאי שנוסד בתחילת המאה העשרים שסייע במלחמות שונות. חברת יפעת מכרזים מספקת פרסומים שוטפים על מכרזי ג'וינט ישראל, לפרטים",
    text: `<b>הג'וינט הוא הארגון היהודי ההומניטרי הגדול ביותר בעולם, המקדם איכות חיים, שוויון הזדמנויות וצמצום פערים בחברה הישראלית. הוא מפרסם מגוון רחב של מכרזים בתחומים שונים במשק, כאשר ההזדמנות לעבוד איתו עשויה להיות דרך יעילה במיוחד לקידום העסק שלכם, להגדלת המחזור שלו ולצמיחה עסקית משמעותית.</b><br/><br/>
            <b>איך תדעו הכול על מכרזי ג'וינט ישראל?</b><br/><br/>
            אולם כיצד תדעו אילו מכרזים והזדמנויות עסקיות מפרסם ג'וינט ישראל בכל רגע נתון?<br/><br/>
            בדיוק עבורכם <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">יפעת מכרזים – החברה המובילה בישראל</a> כבר למעלה מארבעים שנה באיתור, סיווג והנגשה של מכרזים פומביים, סגורים וחיצוניים ושל התקשרויות פטורות ממכרז, מציעה שירות איכותי שיאפשר לכם לבצע חיפוש המותאם במדויק לצרכים העסקיים שלכם, במטרה להבטיח שלא תחמיצו את ההזדמנות העסקית הבאה.<br/><br/>
            במאמר זה המומחים של יפעת מכרזים יסבירו כיצד לבצע חיפוש המותאם במדויק לצרכיכם, אילו כלים מקצועיים אנו מציעים ללקוחותינו וכיצד תוכלו ליצור איתנו קשר.<br/><br/>
            <b>כיצד נעזור לכם לאתר מכרזי ג'וינט ישראל?</b><br/><br/>
            אלו כמה מהשירותים שיאפשרו לכם לאתר מבעוד מועד כל מכרז והזדמנות עסקית בתחומכם, ולסווג את המידע באופן נוח ונגיש שיבטיח שאף מידע רלוונטי לא ילך לכם לאיבוד:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>חיפוש בטכנולוגיה מתקדמת –</b> הסוכן החכם שלנו יפיק את מרב התוצאות הרלוונטיות מביטוי חיפוש כגון ג'וינט ישראל מכרזים ויוודא ששום מכרז או הזדמנות עסקית הקשורים לחיפוש שלכם לא יוחמצו. עם זאת, הוא יסנן עבורכם תוצאות לא רלוונטיות, על מנת שלא תתקשו להתמצא בבליל מידע מיותר.</li>
            <li><b>מידע נגיש, זמין ונוח במגוון פלטפורמות –</b> מעדכונים שוטפים למייל מספר פעמים ביום ועד TrendsMobi, אפליקציית המכרזים הייעודית שלנו – עם יפעת מכרזים תוכלו להיות מעודכנים בכל רגע נתון ולקבל 30,000 מכרזים והזדמנויות עסקיות מדי שנה.</li>
            </ul><br/>
            <b>למה נוח לקבל מאיתנו עדכונים על מכרזי ג'וינט ישראל?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>חיפוש והצגת מידע המותאמים במדויק עבורכם –</b> תוכלו לצפות במידע כשהוא ממוין בדרכים שונות: לפי דרישות המכרז, לפי הגופים המפרסמים או לפי תחומים שונים במשק. המכרזים שלנו מסווגים לפי 74 מקצועות שונים, במטרה להבטיח לכם חיפוש מקיף ולצד זאת ממוקד באופן מיטבי.</li>
            <li><b>ליווי מיומן ומקצועי –</b> עשרות המידענים ומנהלי תיקי הלקוחות שלנו ניחנו בידע ובניסיון רבי שנים ובתודעת שירות מפותחת. הם ישמחו לעמוד לשירותכם ולהתאים את החיפוש ואת השירות באופן המדויק ביותר לצרכים העסקיים הייחודיים שלכם.</li>
            <li><b>תמיכה ושירות מסורים לאורך כל הדרך –</b> צוות התמיכה והשירות המסור שלנו ילווה אתכם לאורך כל שלבי החיפוש שלכם: מהגדרה משותפת ביחד איתכם של מילות החיפוש המדויקות ביותר לסוכן החכם, ויצירת פרופיל חיפוש על סמך ראיון איתכם שיסייע להבין באופן מיטבי את הצרכים העסקיים שלכם, ועד יצירת קשר לבדיקת שביעות רצונכם ולייעול תהליך העבודה בהתאם לצורך.</li>
            </ul><br/>
            <b>מכרזי ג'וינט ישראל – רקורד של הצלחה מוכחת</b><br/><br/>
            במשך למעלה מארבעה עשורים, יפעת מכרזים מובילה את התחום של איתור, סיווג והנגשת מכרזים והזדמנויות עסקיות בישראל. <br/><br/>
            אלו שניים מההישגים המרכזיים שלנו לאורך השנים:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מגוון רחב של לקוחות מרוצים –</b> מבעלי עסקים ומנהלי מכירות ופיתוח עסקי, ועד מנהלי רכש ולוגיסטיקה ומומחי נדל"ן: אלפי לקוחות מעידים על הצמיחה העסקית שחוו בעזרת השירות המותאם אישית שסיפקנו להם.</li>
            <li><b>פיתוח הכלים המקצועיים המובילים בתחום –</b> הסוכן החכם שלנו, שיבצע עבורכם חיפוש מקיף ועם זאת מדויק מאין כמותו, הוא דוגמה לשימוש נכון במיטב הטכנולוגיה המתקדמת ביותר בענף איתור וסיווג המידע בעולם כיום. אפליקציית המכרזים הייעודית שלנו לנייד, TrendsMobi, היא דוגמה נוספת למחויבות שלנו להנגשה מלאה של המידע ללקוחותינו בכל סוגי המכשירים.</li>
            </ul><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "graphicDesign",
    name: "מכרז עיצוב גרפי",
    title: "מכרז עיצוב גרפי: פרסום שוטף של הזדמנויות עסקיות | יפעת מכרזים",
    description:"יפעת מכרזים מרכזת את פרסום מכרזי העיצוב הגרפי במקום אחד. החברה בעלת יותר מ-40 שנות ניסיון ומספקת שירות של סוכן חכם שיציג לכם הזדמנויות עסקיות בלעדיות, לחצו כאן",
    text: `<b>אם גם אתם מתמחים בעיצוב גרפי, אתם יודעים בדיוק עד כמה התקשרות עסקית מתמשכת עם גופים ציבוריים שונים יכולה להעניק לכם את היציבות, הביטחון, אורך הנשימה הכלכלי וסכומי הכסף הגדולים שיאפשרו לכם להתקדם ולצמוח מבחינה עסקית, להגדיל את ההכנסות שלכם ולהתפתח בתחומכם.</b><br/><br/>
            <b>איך לעולם לא תחמיצו מכרז עיצוב גרפי?</b><br/><br/>
            בדיוק משום כך חשוב כל כך להיות מעודכנים בזמן אמת בכל הנוגע למכרזים ולהזדמנויות עסקיות בתחומכם. אולם כיצד תוכלו לוודא שלא תחמיצו אף מכרז או התקשרות שעשויים להיות ההזדמנות שחיכיתם לה לצמיחה עסקית?<br/><br/>
            את יפעת מכרזים הקמנו לפני למעלה מארבעים שנה במטרה לספק מידע מקיף, נגיש ומדויק ללקוחותינו בכל הנוגע למכרזים ולהזדמנויות עסקיות בתחומם. כיום אנו <a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">הגורם המקצועי המוביל בישראל בכל הנוגע לאיתור ולקיטלוג מכרזים</a> פומביים ובלעדיים והתקשרויות ללא מכרז.<br/><br/>
            <b>כיצד נסייע לכם לאתר מכרז עיצוב גרפי?</b><br/><br/>
            אלו חמישה עקרונות יסוד המנחים אותנו לאורך כל פעילותנו:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>מקצועיות –</b> עשרות מנהלי תיקי הלקוחות והמידענים שאנו מעסיקים עומדים בסטנדרטים המחמירים והקפדניים ביותר של מקצועיות בתחום של איתור, סיווג והנגשת מכרזים והזדמנויות עסקיות.</li>
            <li><b>עדכניות –</b> אנו עושים שימוש בכלים הטכנולוגיים המתקדמים ביותר, על מנת להבטיח שהחיפוש שלכם יהיה שלם ומקיף מצד אחד ומדויק וממוקד מצד שני, ושתקבלו את כל המידע הרלוונטי לצמיחה העסקית העתידית שלכם.</li>
            <li><b>איכות –</b> אנו מקפידים לספק אך ורק את השירות והתוצר האיכותיים ביותר ללקוחותינו, מה שבא לידי ביטוי ברמת הצוות שלנו ובאיכות הכלים והשירותים שאנו מציעים.</li>
            <li><b>שירותיות –</b> כל עובדינו ניחנים בתודעת שירות מפותחת ביותר, ומעניקים לכל לקוח ולקוח יחס אנושי, אדיב ואכפתי.</li>
            <li><b>מסירות –</b> אנו מחויבים לספק ללקוחותינו את השירות הטוב ביותר לאורך כל הדרך.</li>
            </ul><br/>
            <b>מכרז עיצוב גרפי – חיפוש ממוקד ומדויק</b><br/><br/>
            בעזרתנו תוכלו לבצע חיפוש מתקדם, מושכל ומדויק באמצעות שימוש בטכנולוגיית המחשוב החדישה ביותר. כך זה מתבצע:<br/></br/>
            <ul style="padding-right: 5rem">
            <li><b>הגדרת חיפוש המותאם במדויק לצרכיכם –</b> צוות התמיכה והשירות שלנו יסייע לכם ליצור פרופיל חיפוש המותאם במדויק לצרכים שלכם, ויגדיר ביחד איתכם את מילות החיפוש הנכונות ביותר עבור הסוכן החכם שלנו – כלי ממוחשב שיאתר באופן מקיף ומלא את כל המכרזים וההזדמנויות העסקיות הרלוונטיות עבורכם, מבלי להחסיר דבר, אך יסנן את כל התוצאות הלא רלוונטיות על מנת לוודא שלא תוצפו במידע מיותר.</li>
            </ul><br/>
            <b>אילו עוד יתרונות יש לנו בחיפוש מכרז עיצוב גרפי?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>קבלת עדכונים שוטפים למייל ולנייד –</b> כדי להבטיח שלא תחמיצו אף מכרז עיצוב גרפי שיוכל לקדם את העסק שלכם, תוכלו לקבל עדכונים למייל מספר פעמים ביום וגם להוריד את TrendsMobi, אפליקציית המכרזים הייעודית שלנו שבאמצעותה תוכלו לצפות במכרזים כשהם ממוינים לפי 74 תחומים שונים במשק.</li>
            <li><b>ליווי, ייעוץ ותמיכה לאורך כל הדרך –</b> בכל עת תוכלו להתייעץ איתנו על מנת לשנות את ההגדרות ולהתאימן לצרכיכם המשתנים. הצוות שלנו ייצור איתכם קשר בהמשך על מנת לוודא את שביעות רצונכם ולייעל במידת הצורך את תהליך העבודה מול המערכת.</li>
            </ul><br/>
            <b>קטגוריות חיפוש של מכרז עיצוב גרפי</b><br/><br/>
            קטגוריות חיפוש הן סוג מידע נוסף שיוכל לסייע לכם בעת ביצוע החיפוש המותאם במדויק ובאופן אישי לצרכים העסקיים הייחודיים שלכם.<br/><br/>
            לצד הפעלת הסוכן החכם וקבלת מידע המותאם במיוחד עבורכם, תוכלו לעיין באופן חופשי במגוון הרחב של מכרזים והזדמנויות עסקיות, באופן הממוין לנוחותכם לפי הגוף המפרסם (למשל, מכרזים של משרד ממשלתי מסוים, של חברת החשמל, של השירות הציבורי, של המשטרה או של מנהל מקרקעי ישראל) או לפי המקצועות השונים (למשל, מכרזי בנייה, נכסים, עבודות חשמל, מקרקעין, הובלות או כמובן, עיצוב גרפי).<br/><br/>
            <b>אנו ביפעת מכרזים נשמח לעמוד לשירותכם. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "unitedHealthInsurence",
    name: "קופת חולים מאוחדת מכרזים",
    title: "קופת חולים מאוחדת מכרזים: עדכונים ופרסומים שוטפים | יפעת מכרזים",
    description: "יפעת מכרזים מספקת את המידע העדכני ביותר ומפרסמת את מכרזי קופת חולים מאוחדת באופן בלעדי, כולל שירות של סוכן חכם שיתריע על פרסום המכרזים הרלוונטים עבורכם. לפרטים",
    text: `<b>אם תחום התמחותכם הופך התקשרות עסקית קבועה עם קופת חולים מסוימת לכדאית עבורכם, חשוב שתדעו בזמן אמת על כל המכרזים וההזדמנויות העסקיות הרלוונטיות שהיא מפרסמת.</b><br/><br/>
            <b>היכן תמצאו הכול על קופת חולים מאוחדת מכרזים?</b><br/><br/>
            זו בדיוק הסיבה שהקמנו לפני למעלה מארבעה עשורים את יפעת מכרזים – החברה המובילה בישראל בתחום האיתור, הסיווג וההנגשה של מכרזים פומביים, בקשות להצעות מחיר והתקשרויות ללא מכרז.<br/><br/>
            <b>למה כדאי להיעזר בנו לאיתור קופת חולים מאוחדת מכרזים?</b><br/><br/>
            אלו כמה דברים שחשוב שתדעו על העבודה איתנו:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>כלים מקצועיים –</b> אנו עושים שימוש בכלים הטכנולוגיים המתקדמים ביותר בתחום המחשוב על מנת להבטיח שהחיפוש שתבצעו יהיה מקיף ומדויק בעת ובעונה אחת, ולא יציף אתכם בבליל מידע מיותר שתתקשו להתמצא בו באופן שעלול לגרום לכם להחמיץ את המידע הרלוונטי.</li>
            <li><b>צוות מנוסה ומיומן –</b> אנו מעסיקים אך ורק את מיטב המידענים ומנהלי תיקי הלקוחות, שיבטיחו שהחיפוש שתבצעו יותאם לצרכים העסקיים שלכם באופן המדויק ביותר.</li>
            <li><b>ערכי יסוד –</b> לאורך כל שנות פעילותנו, הערכים המנחים אותנו הם מקצועיות, מצוינות, איכות, שירותיות, מסירות ללקוחותינו וקדמה טכנולוגית.</li>
            </ul><br/>
            <b>למי כדאי להתעדכן בעזרתנו לגבי קופת חולים מאוחדת מכרזים?</b><br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>סוגי מכרזים והזדמנויות עסקיות –</b> ממכרזים פומביים,   חיצוניים ועד התקשרויות פטורות ממכרז ותוצאות מכרזים, אנו נספק לכם את כל המידע הרלוונטי כדי להבטיח שלא תחמיצו את ההזדמנות הבאה שלכם לצמיחה עסקית.</li>
            <li><b>סוגי לקוחות –</b> מבעלי עסקים ומנהלי מכירות ופיתוח עסקי, ועד מנהלי רכש ולוגיסטיקה ומומחי נדל"ן: אלפי לקוחות ישמחו להעיד על הצמיחה העסקית שחוו בעזרתנו.</li>
            </ul><br/>
            <b>איך נעזור לכם לחפש קופת חולים מאוחדת מכרזים?</b><br/><br/>
            עשרות מנהלי תיקי הלקוחות והמידענים שאנו מעסיקים יסייעו לכם להתאים את החיפוש במדויק לצרכים העסקיים שלכם, ולקבל עדכונים שוטפים למייל ולנייד על כל המכרזים וההזדמנויות העסקיות הרלוונטיות עבורכם.<br/><br/>
            בחלק הבא של המאמר נסביר כיצד זה מתבצע.<br/><br/>
            <b>קופת חולים מאוחדת מכרזים – חיפוש בהתאמה אישית</b><br/><br/>
            בשלב הראשון, צוות התמיכה והשירות שלנו יגדיר ביחד איתכם את מילות החיפוש הנכונות ביותר לסוכן החכם שלנו – כלי מקצועי שבאמצעות טכנולוגיה מתקדמת יבצע חיפוש שיניב מידע מלא ומקיף מצד אחד, ומדויק וממוקד מצד שני, בהתאם לצרכיכם.<br/><br/>
            כך, לדוגמה, ביטוי חיפוש דוגמת קופת חולים מאוחדת מכרזים ישמש את הסוכן החכם שלנו כדי להפעיל שיטות חיפוש חדישות, שיבטיחו שכל מכרז או הזדמנות עסקית שעשויים להיות רלוונטיים יופיעו כחלק מהתוצאות – ולצד זאת, יסנן תוצאות לא רלוונטיות על מנת לוודא שלא תוצפו במידע מיותר שיקשה עליכם לאתר את ההזדמנויות הרלוונטיות.<br/><br/>
            <b>אילו שירותים כלולים בחיפוש קופת חולים מאוחדת מכרזים?</b><br/><br/>
            לצד זאת, צוות התמיכה והשירות שלנו יגדיר ביחד איתכם פרופיל חיפוש בתהליך משותף, שבמהלכו נזקק ביחד איתכם באופן המדויק והנכון ביותר את הצרכים העסקיים שלכם, על מנת לוודא שתקבלו שירות ותוצר מיטביים.<br/><br/>
            בהמשך הדרך, הצוות שלנו ייצור איתכם קשר כדי לוודא שאתם שבעי רצון מהמערכת ולייעל במידת הצורך את תהליך העבודה איתה.<br/><br/>
            <b>קופת חולים מאוחדת מכרזים – ישירות למייל ולנייד</b><br/><br/>
            הזנתם את מילות החיפוש מאוחדת מכרזים לסוכן החכם?<br/><br/>
            כעת תוכלו לקבל למייל מספר פעמים ביום מכרזים והזדמנויות עסקיות שקופת חולים מאוחדת פרסמה, וכן להוריד את TrendsMobi, אפליקציית המכרזים הייעודית שלנו, ולצפות בכל המכרזים וההזדמנויות כשהם ממוינים ל-74 תחומים שונים במשק.<br/><br/>
            באופן כללי, תוכלו לצפות במכרזים כשהם מסווגים לפי הגוף המפרסם (למשל, הממשלה, משרד ממשלתי מסוים, מנהל מקרקעי ישראל וכו') או לפי המקצוע (למשל, מכרזי בנייה, מקרקעין, הובלות או עיצוב גרפי).<br/><br/>
            כך, תוכלו לצפות אך ורק במכרזים של קופת חולים מאוחדת – על מנת לוודא שלא תחמיצו את ההזדמנות העסקית שאתם מעוניינים בה.<br/><br/>
            <b><a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">אנו ביפעת מכרזים נשמח לעמוד לשירותכם</a>. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
  {
    shortName: "nationalHealthInsurence",
    name: "קופת חולים לאומית מכרזים",
    title: "קופת חולים לאומית מכרזים: פרסום מכרזים פעילים | יפעת מכרזים",
    description: "קופת חולים לאומית מפרסמת מכרזים בכל רחבי הארץ ומציעה מגוון הזדמנויות עסקיות לבעלי שירות. יפעת מכרזים מביאה את מכרזי קופת חולים לאומית העדכניים ביותר, למידע נוסף",
    text: `<b>ישנם יתרונות לא מבוטלים להתקשרות עסקית ארוכת טווח עם גוף ציבורי כזה או אחר, באמצעות זכייה במכרז או הזדמנות עסקית אחרת. קופת חולים לאומית, לדוגמה, היא קופת חולים ותיקה המפרסמת מכרזים בתחומים שונים.</b><br/><br/>
            <b>כיצד תדעו הכול על קופת חולים לאומית מכרזים?</b><br/><br/>
            אולם כדי שתוכלו להתמודד ולזכות במכרזים המתאימים ליכולות ולצרכים העסקיים הייחודיים שלכם, ישנם שני תנאים שחייבים להתממש:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>קבלת מידע מקיף ועדכני בזמן אמת –</b> עליכם להתעדכן באופן שוטף בכל המכרזים וההזדמנויות העסקיות הרלוונטיות, על מנת שלא תחמיצו את האפשרות להגיש מועמדות למכרז הספציפי שאתם עונים על דרישותיו מצד אחד, ושזכייה בו תהיה כדאית עבורכם מבחינה עסקית מצד שני. </li>
            <li><b>ביצוע חיפוש מדויק וממוקד –</b> לא מספיק וככל הנראה בלתי אפשרי לעבור על כל המכרזים בכל התחומים. עודף מידע עלול דווקא להקשות עליכם להתמצא בבליל ההודעות שתקבלו, ולגרום לכם להחמיץ דווקא את ההזדמנויות העסקיות הרלוונטיות ביותר מבחינתכם.</li>
            </ul><br/>
            <b>מדוע להיעזר בנו לאיתור קופת חולים לאומית מכרזים?</b><br/><br/>
            אם תנסו לקרוא אינספור מכרזים בעצמכם, לא סביר שתצליחו לעמוד באופן מלא, עקבי ומתמשך בשני התנאים הללו, קרי, לקבל את מלוא המידע לאורך זמן מחד, ולמיין את התוצאות באופן ברור ומדויק בהתאם לצרכיכם מאידך.<br/></br/>
            על מנת לספק לכם את השירות הזה, הקמנו לפני למעלה מארבעים שנה את יפעת מכרזים, שכיום מהווה את הגורם המקצועי המוביל בישראל באיתור, סיווג והנגשת מכרזים והזדמנויות עסקיות.<br/><br>
            <b>שאלתם את הסוכן החכם על קופת חולים לאומית מכרזים?</b><br/><br/>
            באמצעות הסוכן החכם שלנו – כלי טכנולוגי מתקדם העושה שימוש במיטב החידושים בתחום המחשוב, תוכלו לבצע חיפוש המותאם במדויק לצרכיכם העסקיים הייחודיים, לאחר שצוות התמיכה והשירות שלנו יגדיר ביחד איתכם את מילות החיפוש הנכונות ביותר עבורכם.<br/><br/>
            כך, לדוגמה, ביטוי חיפוש כמו קופת חולים לאומית מכרזים יאפשר לכלי החיפוש המתקדם שלנו למצוא עבורכם את כל המכרזים, ההזדמנויות העסקיות והמידע הרלוונטיים – ממכרזים פומביים, סגורים וחיצוניים, דרך התקשרויות ללא מכרז ועד תוצאות מכרזים.</br><br/>
            מצד שני, הסוכן החכם שלנו יסנן את כל התוצאות הלא רלוונטיות, על מנת לוודא שלא תוצפו במידע מיותר שעלול להסתיר מעיניכם את המכרזים וההזדמנויות העסקיות שחשוב שתראו.<br/><br/>
            <b>קופת חולים לאומית מכרזים – אצלכם בכל המכשירים</b><br/><br/>
            אלו רק כמה מהיתרונות הטמונים בעבודה איתנו:<br/><br/>
            <ul style="padding-right: 5rem">
            <li><b>כל המידע יישלח אליכם ישירות בכל הערוצים –</b> מ-30,000 מכרזים והזדמנויות עסקיות מדי שנה, שתיחשפו אליהם בעדכונים שיישלחו אליכם מספר פעמים ביום למייל, ועד TrendsMobi, אפליקציית המכרזים הייעודית שלנו שתאפשר לכם לצפות במכרזים כשהם ממוינים לפי 74 תחומי משק שונים. </li>
            <li><b>אנו נלווה אתכם לאורך כל הדרך –</b> צוות התמיכה והשירות שלנו יגדיר איתכם את מילות החיפוש המדויקות ביותר לסוכן החכם, ייצור איתכם במשותף פרופיל חיפוש העונה באופן המיטבי על הצרכים שלכם, וייצור איתכם קשר בהמשך כדי לוודא את שביעות רצונכם ובמידת הצורך, לייעל את העבודה עבורכם.</li>
            <li><b>תוכלו תמיד לעדכן ולשנות את הגדרות החיפוש –</b> אם הצרכים וההעדפות שלכם השתנו, נוכל תמיד לסייע לכם להגדיר מחדש את הסוכן החכם ואת פרופיל החיפוש שלכם בהתאם. הזנתם את הביטוי לאומית מכרזים וקיבלתם תוצאות רלוונטיות? מעולה. תמיד תוכלו להוסיף גם ביטויים אחרים. </li>
            </ul><br/>
            <b>למי כדאי להיעזר בנו לאיתור קופת חולים לאומית מכרזים?</b><br/><br/>
            בין לקוחותינו ניתן למנות בעלי עסקים, מנהלי רכש ולוגיסטיקה, מומחי נדל"ן ומנהלי פיתוח עסקי ומכירות. המכנה המשותף לכולם הוא הרצון להגדיל את ההכנסות של העסק שלהם ולאפשר לו לצמוח באופן בר קיימא וארוך טווח, הודות להתקשרות קבועה עם גוף ציבורי, לרוב באמצעות מכרז.<br/><br/>
            <b><a href='https://www.tenders.co.il/home' style="text-decoration: underline; color: blue !important;!i;!;">אנו ביפעת מכרזים נשמח לעמוד לשירותכם</a>. התקשרו אלינו עוד היום לטלפון: 03-5635000 ותנו לנו לפתוח עוד דלתות עבורכם.</b>
    `,
  },
];
